import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";
import { useWindowSize } from "api/commonConstants";
import { useRouter } from "next/router";
// import { useEffect } from "react";
import ReadNewsCardNew from "../../newsCard/ReadNewsCardNew/index";

// import { QuizCard } from "components/quiz/quizCard";
import GoodMorning from "components/homePage/goodMorning/goodmorning";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
// import Sponsor from "components/Sponsor";
// import getConfig from "next/config";
import NewsBannerType2 from "components/newsBanner/Type2";
import { useEffect } from "react";
import {
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from "firebase/remote-config";
import app from "utils/firebase";
import { useContext } from "react";
import { MasterConfigContext } from "constants/MasterConfigContext";
// import NewsWidget from "components/NewsWidget";
import NewsCarousel from "components/cricket/NewsCarousel";
const QuickLinks = dynamic(() => import("components/QuickLinks"), { ssr: false });
const QuickLinksMobile = dynamic(() => import("components/QuickLinksMobile"), { ssr: false });

const DynamicBanner = dynamic(() =>
  import("components/homePage/dynamicBanner/bannerCarousel")
);
// const ReadNewsCard = dynamic(() =>
//   import("components/newsCard/readNewsCard/index.js"),{ssr:true}
// );
// const AddaBannerCategory = dynamic(() =>
//   import("components/homePage/addaBannerCategory")
// );
const LiveElectionCard = dynamic(() =>
  import("components/homePage/liveShow/liveElectionCard")
);
// const GoodMorning = dynamic(() =>
//   import("components/homePage/goodMorning/goodmorning")
// );
const LiveShow = dynamic(
  () => import("components/homePage/liveShow/liveShow"),
  { ssr: false }
);
const NewsBannerType1 = dynamic(() => import("components/newsBanner/Type1"), {
  ssr: true,
});
// const NewsBannerType2 = dynamic(() => import("components/newsBanner/Type2"), {
//   ssr: true,
// });
// const CricketSlider = dynamic(
//   () => import("components/homePage/cricketSlider"),
//   { ssr: true }
// );

const MainContainer = ({
  featuredPosts = [],
  bannerData = [],
  liveShowData = [],
  // cricketResponse = [],
  firstScroll = false,
  quizPage = [],
  masterObj,
  newsData = []
}) => {
  const masterConfig = useContext(MasterConfigContext);
  console.log("bannerData", masterConfig);
  const [isLiveActive, setIsLiveActive] = useState(false);
  const [publicRuntimeConfig, setPublicRunTimeConfig] = useState({
    electionExitPollToggle: false,
    electionResultToggle: true,
    vidhansabhaResultToggle: true,
    resultVersion: 1.6,
    exitPollVersion: 1.2,
    electionYear: 2024,
    isElectionAlpha: true,
    keyCandidate: true,
  });

  useEffect(() => {
    if (liveShowData?.totalCount > 0 || liveShowpostData?.totalCount > 0) {
      setIsLiveActive(true);
    }
    if (typeof window !== "undefined") {
      const remoteConfig = getRemoteConfig(app);
      remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

      fetchAndActivate(remoteConfig)
        .then(() => {
          const titleData = getString(remoteConfig, "electionKeys");
          const parsedData = JSON.parse(titleData);
          setPublicRunTimeConfig(parsedData);
          console.log("Election Keys are ", parsedData);
          // setVideoUrl(titleData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => { });
  const size = useWindowSize();
  const router = useRouter();

  const [loadAfterTime, setLoadAfterTime] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoadAfterTime(true)
    }, [3000])
  }, [])

  // const blogsListFirst = useSelector((data) => data.liveblogReducer?.blogsList);

  // const blogsList = [];
  // if (blogsListFirst.length > 0 && blogsListFirst[0].eventStatus === "live") {
  //   blogsList.push(blogsListFirst[0]);
  // }

  // console.log("Blogs list is ", blogsList);

  const [language, setLanguage] = useState("hindi");

  // useEffect(() => {
  //   setLanguage(getLanguage());
  // }, []);
  // const [isMobile,setIsMobile] = useState(false);
  // const [isDesktop, setIsDesktop] = useState(false);

  // const isMobile =
  //   typeof window !== "undefined" &&
  //   window.matchMedia("(max-width: 600px)").matches;
  // const isDesktop =
  //   typeof window !== "undefined" &&
  //   window.matchMedia("(min-width: 600px)").matches;

  const { liveShowpostData } = useSelector(({ PostReducer }) => PostReducer);

  // useEffect(() => {
  //   let obj = {
  //     bannerImage:
  //       "https://static.thelallantop.com/1679038971660MicrosoftTeams-image%20%289%29.png?width=400",
  //     index: 2,
  //     type: "WEBVIEW",
  //     title: "quiz",
  //     mWebBannerImage:
  //       "https://static.thelallantop.com/1679038971660MicrosoftTeams-image%20%289%29.png?width=400",
  //   };
  //   // let obj1 = {
  //   //   bannerImage:
  //   //     "https://static.thelallantop.com/images/post/1689922313619_microsoftteams-image_(10).webp?quality=40",
  //   //   index: 2,
  //   //   type: "WEBVIEW",
  //   //   title: "adda",

  //   //   deepLink: "https://adda.thelallantop.com/?userId=%22home%22",
  //   //   mWebBannerImage:
  //   //     "https://static.thelallantop.com/images/post/1689922313619_microsoftteams-image_(10).webp?quality=40",
  //   // };
  //   // if (!bannerData.includes(obj1) && window.innerWidth > 500) {
  //   //   bannerData.push(obj1);
  //   // }
  //   // if (!bannerData.includes(obj)) {
  //   //   bannerData.push(obj);
  //   // }
  //   bannerData.push(obj);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  console.log(
    'bannerData["largeBannerDesktopData"]',
    bannerData["largeBannerDesktopData"]
  );

  return (
    <section id="mainContainer" style={{ marginBottom: "1.5rem" }}>
      {router.pathname === "/" && (
        <div className="outer-main-conainer">

          {/* { publicRuntimeConfig?.electionYear == "2024" &&  <Sponsor color="#d9368b" name="चुनाव" />} */}
          <div className="main-container-featured1 pt-3">
            {loadAfterTime && size.width > 950 && <QuickLinks />}
            <div className="mt-3"></div>
            {size.width > 768 &&
              bannerData["largeBannerDesktopData"] &&
              bannerData["largeBannerDesktopData"]?.length > 0 && (
                <a
                  href={bannerData["largeBannerDesktopData"][0]?.deepLink}
                  onClick={() => {
                    ga("create", "G-RXRPKR4310", "auto");
                    ga("send", "event", {
                      eventCategory: "banner",
                      eventAction: "Click",
                      eventLabel: "featured",
                      eventValue: `${bannerData["largeBannerDesktopData"][0]?.title}`,
                    });
                  }}
                >
                  <img
                    src={bannerData["largeBannerDesktopData"][0]?.bannerImage}
                    className="top-banner-image"
                  />
                </a>
              )}
            {/* <QuickLinks/> */}
            <div className="content">
              <div className="left-area-Col">

                {/* {isMobile && <AddaBannerCategory />} */}

                {/* {bannerData?.length > 0 && size?.width < 768 && (
                  <div className="">
                    <DynamicBanner
                      isMobile={true}
                      list={bannerData}
                      type={"msite"}
                    />
                  </div>
                )} */}
                {console.log(
                  ' bannerData["carouselBannersMobileData"]',
                  bannerData
                )}
                {size.width < 768 &&
                  bannerData["carouselBannersMobileData"] &&
                  bannerData["carouselBannersMobileData"]?.length > 0 && (
                    <DynamicBanner
                      isMobile={true}
                      list={bannerData["carouselBannersMobileData"]}
                      type={"msite"}
                      page={"featured"}
                    />
                  )}
                {size.width <= 950 &&
                  masterConfig?.homeIframe?.home_widget &&
                  masterConfig?.homeIframe?.homeIframeUrl?.length > 0 && (
                    <div style={{ display: "relative" }}>
                      <a
                        style={{
                          position: "absolute",
                          height: "116px",
                          width: "350px",
                        }}
                        href={masterConfig?.homeIframe?.homeIframeUrlHref ?? ""}
                      ></a>
                      <iframe
                        src={masterConfig?.homeIframe?.homeIframeUrl}
                        width="100%"
                        height={"126"}
                        style={{ marginTop: "-8px" }}
                      ></iframe>
                    </div>
                  )}
                {/* <div
                  className="checkMobileOrNot"
                  style={{ minHeight: "180px" }}
                >
                  <CricketSlider
                    cricketResponse={cricketResponse}
                    firstScroll={firstScroll}
                  />
                </div> */}
                {/* {quizPage[0]?.quizId !== "" && (
                  <div
                    className="checkMobileOrNot"
                    style={{ marginTop: "2rem" }}
                  >
                    <QuizCard key={0} data={quizPage[0]} type="live"></QuizCard>
                  </div>
                )} */}

                {/* <div
                  className="checkMobileOrNot"
                  style={{ minHeight: "180px" }}
                >
                  <CricketSlider
                    cricketResponse={cricketResponse}
                    firstScroll={firstScroll}
                  />
                </div> */}

                <div className="checkDesktopOrNot">
                  {featuredPosts?.slice(0, 1)?.map((postData, _) => (
                    <Col key={`${postData?._id}`}>
                      <NewsBannerType1 postData={postData} />
                    </Col>
                  ))}
                </div>

                <div className="checkMobileOrNot">
                  <LiveElectionCard />
                  {loadAfterTime && <QuickLinksMobile />}
                  {featuredPosts?.slice(0, 1)?.map((postData, _) => (
                    <Col key={`${postData?._id}`}>
                      <NewsBannerType2 postData={postData} />
                    </Col>
                  ))}
                </div>
                {/* <div
                  className="checkMobileOrNot"
                  onClick={() =>
                    router.push(
                      "https://adda.thelallantop.com/?userId=%22home%22"
                    )
                  }
                  style={{
                    minHeight: "175px",
                    marginTop: "5px",
                    cursor: "pointer",
                    border: "1px solid black;",
                  }}
                >
                  <img
                    className="one-px-solid-br"
                    style={{ minWidth: "100%" }}
                    src={`https://static.thelallantop.com/images/post/1698921639782_414x212.webp?${
                      !firstScroll ? "width=100" : ""
                    }`}
                    alt="adda-banner"
                  />
                </div> */}

                {size.width < 768 && !(masterObj?.MoveAdsUp == true) && (
                  <div
                    className="container-row py-2 mobile-header-ad"
                    align="center"
                  >
                    <p
                      style={{
                        fontSize: "11px",
                        color: "#b7b7b7",
                        marginBottom: "0px",
                      }}
                    >
                      Advertisement
                    </p>

                    {size.width < 768 && (
                      <div
                        id={"div-gpt-ad-1701946028239-0"}
                        style={{
                          minHeight: "260px",
                          alignContent: "center",
                          textAlign: "-webkit-center",
                        }}
                      >
                        <script
                          type="text/javascript"
                          defer
                          dangerouslySetInnerHTML={{
                            __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})`,
                          }}
                        ></script>
                      </div>
                    )}
                  </div>
                )}

                {/* 1,2 */}
                {featuredPosts?.slice(1, 2)?.map((postData, index) => (
                  <Col key={`${postData?._id}`} className="featuredCards">
                    <ReadNewsCardNew
                      key={`index_${index}_${postData?._id}`}
                      postData={postData}
                    />
                  </Col>
                ))}

                {/* 2,5 */}

                {featuredPosts?.slice(2, 5)?.map((postData, index) => (
                  <Col key={`${postData?._id}`} className="featuredCards">
                    <ReadNewsCardNew
                      key={`index_${index}_${postData?._id}`}
                      postData={postData}
                    />
                  </Col>
                ))}
                {isLiveActive &&
                  featuredPosts?.slice(11, 12)?.map((postData, index) => (
                    <Col key={`${postData?._id}`} className="featuredCards">
                      <ReadNewsCardNew
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                      />
                    </Col>
                  ))}
              </div>

              <div className="left-area-Col mobile-second-col">
                {/* <div className="checkMobileOrNot" onClick={()=> router.push('https://adda.thelallantop.com/?userId=%22home%22')} style={{ minHeight: "80px", marginTop:"-10px",cursor:"pointer" }}>
        <img  style={{minWidth:"100%"}} src={`https://static.thelallantop.com/images/post/1695990901631_774x172e.webp?${!firstScroll?"width=200":""}`} alt="adda-banner"/>
      </div>
              <div className="checkDesktopOrNot" onClick={()=> router.push('https://adda.thelallantop.com/?userId=%22home%22')} style={{ minHeight: "90px", marginTop:"4px", cursor:"pointer" }}>
        <img   src={`https://static.thelallantop.com/images/post/1695990901631_774x172e.webp?${!firstScroll?"width=200":""}`} alt="adda-banner" style={{borderRadius:"1rem",minWidth:"100%"}}/>
      </div> */}
                {size?.width < 768 &&
                  (liveShowData?.totalCount > 0 ||
                    liveShowpostData?.totalCount > 0) && (
                    <div className="live-mobile">
                      <LiveShow liveShowData={liveShowData} />
                      {/* <video width="100%" autoPlay={true} controls src="https://vod.thelallantop.com/output-videos-transcoded/vod_28_Jul_2024_robert_downey_jr_1_421/video.mp4"></video> */}
                    </div>
                  )}
                {size.width > 768 &&
                  bannerData["carouselBannersDesktopData"] &&
                  bannerData["carouselBannersDesktopData"]?.length > 0 && (
                    <DynamicBanner
                      list={bannerData["carouselBannersDesktopData"]}
                      type={"msite"}
                      page={"featured"}
                    />
                  )}

                {/* <div className="goodmorning-mobile">
                  <GoodMorning />
                </div> */}
                {/* <div className="checkDesktopOrNot" onClick={()=> router.push('https://adda.thelallantop.com/?userId=%22home%22')} style={{ minHeight: "90px", marginTop:"4px", cursor:"pointer" }}>
        <img   src={`https://static.thelallantop.com/images/post/1695990901631_774x172e.webp?${!firstScroll?"width=200":""}`} alt="adda-banner" style={{borderRadius:"1rem",minWidth:"100%"}}/>
      </div> */}
                {/* 5,10 */}
                { }
                {size.width > 768 && masterConfig['ChampionsTrophyWidget']['SmallHomeBannerDesktop'] && <NewsCarousel newsData={newsData} />}
                {featuredPosts
                  ?.slice(
                    5,
                    bannerData["carouselBannersDesktopData"]?.length == 0 ||
                      isLiveActive
                      ? 11
                      : 10
                  )
                  ?.map((postData, index) => (
                    <Col
                      key={`${postData?._id}`}
                      className="featuredCards"
                      style={index === 0 ? { marginTop: "8px" } : undefined}
                    >
                      <ReadNewsCardNew
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                      />
                    </Col>
                  ))}
                {size.width > 950 &&
                  masterConfig?.homeIframe?.home_widget &&
                  masterConfig?.homeIframe?.homeIframeUrl?.length > 0 && (
                    <div style={{ display: "relative" }}>
                      <a
                        style={{
                          position: "absolute",
                          height: "100px",
                          width: "350px",
                        }}
                        href={masterConfig?.homeIframe?.homeIframeUrlHref ?? ""}
                      ></a>
                      <iframe
                        src={masterConfig?.homeIframe?.homeIframeUrl}
                        width="100%"
                        height={"124"}
                        style={{ marginTop: "-8px" }}
                      ></iframe>
                    </div>
                  )}
                {/* {blogsList?.length > 0 &&
                  featuredPosts?.slice(4, 9)?.map((postData, index) => (
                    <Col key={`${postData?._id}`} className="featuredCards">
                      <ReadNewsCardNew
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                      />
                    </Col>
                  ))} */}
              </div>
              <div className="right-area-Col">
                {size?.width > 950 &&
                  (liveShowData?.totalCount > 0 ||
                    liveShowpostData?.totalCount > 0) && (
                    <div className="checkDesktopOrNot">
                      <LiveShow liveShowData={liveShowData} />
                    </div>
                  )}


                {<div className="checkDesktopOrNot">{<GoodMorning />}</div>}

                {/* <div
                    className="container"
                    style={
                      liveShowpostData?.totalCount === 0
                        ? { padding: "10px" }
                        : {}
                    }
                  > */}
                {/* {isDesktop && (
                  <GoogleAdContainer divId="div-gpt-ad-1686738608479-1" />
                )} */}

                {/* {loadAfterTime && size.width > 950 && <QuickLinks />} */}
                <div
                  className="container-row py-2 deaktop-header-ad"
                  align="center"
                >
                  {/* <h5
                    id="ads"
                    style={{
                      fontSize: "11px",
                      color: "#b7b7b7",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    Advertisement
                  </h5> */}
                  {size.width > 950 && (
                    <div
                      id="div-gpt-ad-1701945220410-0"
                      style={{
                        minHeight: "260px",
                        alignContent: "center",
                        textAlign: "-webkit-center",
                      }}
                    >
                      <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                          __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-0')})`,
                        }}
                      ></script>
                    </div>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer></ToastContainer>
    </section>
  );
};

export default MainContainer;
